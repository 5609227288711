// localization
import '@angular/localize/init';

// IE 11 necessary polyfills
import 'intersection-observer';

// safari < 15
import 'core-js/proposals/relative-indexing-method';

// Create Element.remove() function if not exist
if (!Element.prototype.remove) {
  Element.prototype.remove = function () {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}

// import './types';

// Algolia bugfix for some browsers
(window as any).process = {
  env: { DEBUG: undefined },
};

// flattens an array of arrays to a flat array
// Array.prototype.flat = function () {
//   return this.reduce((acc, val) => acc.concat(val), []);
// };

// Polyfill for 'matches' in IE
interface Element {
  matchesSelector(selectors: string): boolean;
  mozMatchesSelector(selectors: string): boolean;
  msMatchesSelector(selectors: string): boolean;
  oMatchesSelector(selectors: string): boolean;
  webkitMatchesSelector(selectors: string): boolean;
}

// IE fix
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype['msMatchesSelector'] ||
    Element.prototype['matchesSelector'] ||
    Element.prototype['mozMatchesSelector'] ||
    Element.prototype['oMatchesSelector'] ||
    Element.prototype['webkitMatchesSelector'] ||
    function (s) {
      let matches = (this.document || this.ownerDocument).querySelectorAll(s),
        i = matches.length;
      while (--i >= 0 && matches.item(i) !== this) {}
      return i > -1;
    };
}

//extend FileReader for IE
if (!FileReader.prototype.readAsBinaryString) {
  FileReader.prototype.readAsBinaryString = function (fileData) {
    let binary = '';
    const pt = this;
    const reader = new FileReader();
    reader.onload = function (e) {
      const bytes = new Uint8Array((<any>reader).result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      // since FileReader.result is readonly, we can't set pt.result
      // we mimic a return event with a target.result, which in our use case
      // is the only thing we're interested in
      pt.onload({
        target: {
          result: binary,
        },
      });
    };
    reader.readAsArrayBuffer(fileData);
  };
}

/**
 * Ionic:
 * Prevents Angular change detection from
 * running with certain Web Component callbacks
 */
(window as any).__Zone_disable_customElements = true;
